.btn-modal {
    background: var(--button-greenfavorite) !important;
    color: #ffffff !important;
    letter-spacing: 1px;
}
.eathubtitle {
    color: var(--paragraph) !important;
    letter-spacing: 1px !important;
}
:focus{
    outline: none !important;
}
.eathubtext {
    letter-spacing: 1px !important;
}