@media (min-width: 1200px) {
    .form-div {
        width: 55% !important;
        margin: auto;
        margin-top: 10% !important;
    }
}
@media (min-width: 992px) {
    .form-div {
        width: 80% !important;
        margin-top: 13% !important;
        margin: auto;
    }
}
@media only screen and (min-width: 768px) {
    .form-div {
        width: 80% !important;
        margin-top: 13% !important;
        margin: auto;
    }
}
@media only screen and (min-width: 576px) {
    .form-div {
        width: 80% !important;
        margin-top: 13% !important;
        margin: auto;
    }
}