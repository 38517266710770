.blog-div {
  width: 60% !important;
  margin: auto !important;
}

.blog-header {
  color: var(--heading);
  letter-spacing: 1px;
}

@media all and (max-width: 1024px) {
  .blog-div {
    width: 90% !important;
  }

  .blog-header {
    font-size: 23px !important;
  }
}