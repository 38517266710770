#home .view {
  background-image: url("../../assets/homepage.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  width: 100%;
}

.logo-img {
  height: 7em;
  width: 7em;
}

.icon-img {
  height: 2.5em;
  width: 2.5em;
  margin: auto !important;
}

.res-div {
  width: 60% !important;
  margin: auto;
}

.black-text {
  letter-spacing: 1px;
}


.bannerTitle {
  padding-top: 70px;
}

.bannerText {
  font-size: 15px;
  letter-spacing: 1px;
}


.h2-responsive {
  color: #ff7e00;

}

.wa {
  position: fixed;
  right: 0em !important;
  bottom: 0em;
  color: black;
}

.wa-img {
  height: 3em;
  width: 3em;
  right: 0em !important;
}

.btn-video {
  background: var(--button-greenfavorite) !important;
  color: #ffffff !important;
  width: 100% !important;
  margin-left: 0em !important;
  letter-spacing: 1px;
}
.a{
  color: #ffffff !important;
}
.btn-blog {
  background: var(--button-greenfavorite) !important;
  color: #ffffff !important;
  width: 100%;
  margin-left: 0em;
  letter-spacing: 1px
}

.icon-para {
  font-size: 11px;
  letter-spacing: 2px;
  text-align: center;
}

.grid {
  margin-top: 1.5em;
}

.header {
  color: #739749;
  margin-top: 1.5em;
  letter-spacing: 1px;
}

.anav {
  letter-spacing: 1px;
  color: #ffffff !important;
}

@media all and (max-width: 1024px) {

  #home .view {
    background-image: url("../../assets/mobile-home.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
    width: 100%;
  }

  .logo-img {
    height: 6em;
    width: 6em;
  }

  .icon-img {
    height: 3em;
    width: 3em;
    margin: auto;
  }

  .black-text {
    font-size: 15px !important;
  }

  .res-div {
    width: 90% !important;
    margin: auto;
  }

  .icon-para {
    font-size: 11px;
    letter-spacing: 2px;
    text-align: center;
  }

  .h3-responsive {
    color: #df702b;
    letter-spacing: 1px;

  }

  .bannerText {
    font-size: 16px;
    letter-spacing: 1px;
    text-align: center;
  }

  .bannerTitle-text {
    margin-top: 2em !important;
    text-align: center;
    font-size: 22px !important;
    letter-spacing: 0.5px;
  }

  .h2-responsive {
    color: #ff7e00;
  }

  .btn-video {
    background: var(--button-greenfavorite) !important;
    color: #ffffff !important;
    width: 100% !important;
    margin-left: 0em !important;
    letter-spacing: 1px;
    font-size: 12px !important;
  }

  .btn-exit {
    background: var(--button-greenfavorite) !important;
    color: #ffffff !important;
  }

  .btn-blog {
    background: var(--button-greenfavorite) !important;
    color: #ffffff !important;
    width: 100%;
    margin-left: 0em;
    letter-spacing: 1px;
  }

  .header {
    color: #739749;
    margin-top: 1.5em;
    font-size: 18px !important;
  }

  .anav {
    letter-spacing: 1px;
    color: #000000 !important;
  }

}