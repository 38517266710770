.footer-section{
    background-color: black;
    color: blanchedalmond;
    display: flex;
    padding-top: 30px;
    padding-bottom: 20px;
}

.footer1{
    flex: 2;
    padding-left: 100px;
    display: flex;
    align-items: center;
    align-content: center;
}

.logoFooter {
    height: 230px;
}
.divf {
    background-color: var(--heading);
}