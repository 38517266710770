.features-div {
  width: 60% !important;
  margin: auto !important
}

.why-header {
  letter-spacing: 1px;
  color: var(--heading);
}

@media all and (max-width: 1024px) {
  .features-div {
    width: 90% !important;
  }
  .why-header {
    font-size: 23px !important;
  }
}