.intro-div {
    width: 60% !important;
    margin: auto !important;
}
.intro-header {
    color: #73964c;
    letter-spacing: 1px;
}

.href {
    color: #73964c;
}
@media all and (max-width: 1024px) {
    .intro-div {
        width: 90% !important;
    } 
    .intro-header {
        font-size: 23px !important;
    }
}