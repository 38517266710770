.test-div {
    width: 60% !important;
    margin: auto !important;
}

.test-header {
    color: var(--heading);
    letter-spacing: 1px;
}

.testimg1 {
    height: 6em !important;
    width: 6em !important;
}
.testimg2 {
    height: 6em !important;
    width: 15em !important;
}
.card {
    width: 100% !important;
}
.testimg3{
    height: 6em !important;
    width: 6em !important;
}

.carousel-div {
    width: 80% !important;
    margin: auto !important;
}

@media all and (max-width: 1024px) {
    .test-div {
        width: 90% !important;
    }

    .test-header {
        font-size: 23px !important;
    }
    .carousel-div {
        width: 90% !important;
    }
}