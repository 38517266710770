.operations-div {
    width: 60% !important;
    margin: auto !important;
}

.image2 {
    height: 400px;
    width: 400px;
}

.op-header {
    color: var(--heading);
    letter-spacing: 1px;
}

@media all and (max-width: 1024px) {
    .operations-div {
        width: 90% !important;
    }

    .op-header {
        font-size: 23px !important;
    }
}